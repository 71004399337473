import { useState } from 'react';
import TableContainer from '../../../../components/TableContainer';
import {
  ClientSession,
  ClientSessionGroup,
  ClientSessionIndividual,
  ClientSessionParticipant,
} from '../../../../../generated/graphql';
import SessionActions from './SessionActions';
import IndividualSessionRow from './IndividualSessionRow';
import GroupSessionRow from './GroupSessionRow';
import classNames from 'classnames';
import DismissModal from '../modals/DismissModal';
import { useTableStore } from '../../hooks/useTableStore';
import { useSessionsData } from '../../hooks/useSessionData';
import Pagination from '../Pagination';
import { useSessionsStore } from '../../hooks/useSessionStore';

export type Selectable = ClientSession | ClientSessionParticipant;

const isGroup = (item: ClientSession): item is ClientSessionGroup => {
  return item.__typename === 'ClientSessionGroup';
};

const isIndividual = (item: ClientSession): item is ClientSessionIndividual => {
  return item.__typename === 'ClientSessionIndividual';
};

const ClientSessionsTable: React.FC = () => {
  const {
    sessions,
    pageInfo,
    totalCount,
    refetch: refetchSessions,
  } = useSessionsData();
  const {
    selectedSessions,
    expandedGroups,
    toggleSelection,
    toggleGroupSelection,
    toggleGroup,
    clearSelection,
  } = useTableStore();
  const [showDismissModal, setShowDismissModal] = useState(false);
  const [sessionsToDelete, setSessionsToDelete] = useState<ClientSession[]>([]);
  const { setNextPage, setPreviousPage } = useSessionsStore();

  const handleDismiss = (sessions: ClientSession[]) => {
    setSessionsToDelete(sessions);
    setShowDismissModal(true);
  };

  const handleDismissSuccess = async () => {
    clearSelection();
    await refetchSessions();
  };

  const handleNextPage = () => {
    setNextPage();
  };

  const handlePreviousPage = () => {
    setPreviousPage();
  };

  return (
    <div className="mx-auto w-full">
      {showDismissModal && (
        <DismissModal
          sessions={sessionsToDelete}
          onClose={() => setShowDismissModal(false)}
          onSuccess={handleDismissSuccess}
        />
      )}

      <div className="sticky top-0 z-10 bg-white">
        <SessionActions
          selectedSessions={selectedSessions}
          clearSelection={clearSelection}
          sessions={sessions as ClientSession[]}
          onDismiss={handleDismiss}
        />

        {/* Header */}
        <div className="grid min-w-full grid-cols-[auto,1fr,1fr,1fr,1fr,auto] gap-4 py-2 px-4 text-left text-small-caption font-medium text-neutral-150">
          <div className="w-6" />
          <div className="min-w-[150px]">Client</div>
          <div className="min-w-[100px] max-w-[220px]">Care Team</div>
          <div className="min-w-[110px]">Title</div>
          <div className="min-w-[110px]">Date & Time</div>
          <div className="w-24">Actions</div>
        </div>
      </div>

      <TableContainer>
        <div className="flex w-full flex-col">
          <div className={classNames('w-full divide-y divide-neutral-50')}>
            {sessions.map((session: ClientSession) => {
              if (isIndividual(session)) {
                return (
                  <IndividualSessionRow
                    key={session.id}
                    session={session}
                    selectedSessions={selectedSessions}
                    onToggleSelection={toggleSelection}
                    onDismiss={(session) => handleDismiss([session])}
                  />
                );
              }

              if (isGroup(session)) {
                return (
                  <GroupSessionRow
                    key={session.id}
                    group={session}
                    selectedSessions={selectedSessions}
                    expandedGroups={expandedGroups}
                    onToggleGroup={toggleGroup}
                    onToggleSelection={toggleSelection}
                    onToggleGroupSelection={toggleGroupSelection}
                  />
                );
              }

              return null;
            })}
          </div>
          <div className="mt-2 flex justify-center">
            <Pagination
              hasNextPage={pageInfo?.hasNextPage ?? false}
              hasPreviousPage={pageInfo?.hasPreviousPage ?? false}
              totalCount={totalCount}
              currentCount={sessions.length}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
};

export default ClientSessionsTable;
