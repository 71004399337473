import { ClientSessionReviewStatus } from '../../../../generated/graphql';

export const CLIENT_SESSIONS_PENDING_BASE_PATH = '/client_sessions/pending';
export const CLIENT_SESSIONS_CONFIRMED_BASE_PATH = '/client_sessions/confirmed';
export const CLIENT_SESSIONS_DISMISSED_BASE_PATH = '/client_sessions/dismissed';
export const CLIENT_SESSIONS_NEW_BASE_PATH = '/client_sessions/new';

export const PAGE_SIZE = 10;

export const SESSION_STATES = {
  PENDING: {
    path: CLIENT_SESSIONS_PENDING_BASE_PATH,
    status: ClientSessionReviewStatus.PendingReview,
    title: 'To review',
    label: 'To review',
    description:
      'Review and validate these sessions that we have detected from your calendar.',
  },
  CONFIRMED: {
    path: CLIENT_SESSIONS_CONFIRMED_BASE_PATH,
    status: ClientSessionReviewStatus.Confirmed,
    title: 'Confirmed',
    label: 'Confirmed',
    description:
      'View all your confirmed client sessions that have been reviewed and validated.',
  },
  DISMISSED: {
    path: CLIENT_SESSIONS_DISMISSED_BASE_PATH,
    status: ClientSessionReviewStatus.Dismissed,
    title: 'Dismissed',
    label: 'Dismissed',
    description:
      'View sessions that you have marked as not being client sessions.',
  },
} as const;
