import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Config from '../../../lib/config';

import PageContainer from '../../components/Containers/PageContainer';

import { useAuth } from '../../../contexts/AuthContext';
import SelectMenu from '../../components/SelectMenu';
import PerformanceOverview from './PerformanceOverview';
import PractitionerList from './PractitionerList';

import {
  TimeSeriesInterval,
  useProviderSummaryQuery,
  usePractitionerAssignmentsQuery,
  Direction,
  Impact,
} from '../../../generated/graphql';

import { formatDuration } from '../../lib/time';

const PERIOD_OPTIONS = [
  ...(Config.REACT_APP_ENVIRONMENT === 'development'
    ? [{ label: 'Today', value: TimeSeriesInterval.Day }]
    : []),
  { label: 'This week', value: TimeSeriesInterval.Week },
  { label: 'This month', value: TimeSeriesInterval.Month },
  { label: 'This year', value: TimeSeriesInterval.Year },
];

const InsightsPage = () => {
  const [period, setPeriod] = useState<TimeSeriesInterval>(
    TimeSeriesInterval.Week,
  );

  const { authedProviderUser, showUpgradeBanner } = useAuth();

  const {
    data: summaryData,
    loading: summaryLoading,
    refetch: refetchSummary,
  } = useProviderSummaryQuery({
    variables: {
      providerId: authedProviderUser.provider.id,
      input: { interval: period },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    // This ensures the cache is used between queries
    canonizeResults: true,
  });

  const { data: practitionersData, loading: practitionersLoading } =
    usePractitionerAssignmentsQuery({
      variables: {
        providerId: authedProviderUser.provider.id,
      },
    });

  // Keep track of previous data
  const previousSummaryDataRef = useRef(summaryData);
  useEffect(() => {
    if (!summaryLoading && summaryData) {
      previousSummaryDataRef.current = summaryData;
    }
  }, [summaryLoading, summaryData]);

  // Use previous data while loading, current data otherwise
  const displayData = summaryLoading
    ? previousSummaryDataRef.current
    : summaryData;

  const performanceData = useMemo(() => {
    const summary = displayData?.providerPerformanceSummary;
    return [
      {
        label: 'Sessions',
        value: summary?.totalSessions?.current || 0,
        changeInPercentage: summary?.totalSessions?.changeInPercentage || 0,
        direction: summary?.totalSessions?.direction || Direction.Neutral,
        impact: summary?.totalSessions?.impact || Impact.Neutral,
      },
      {
        label: 'Practitioners',
        value: summary?.totalPractitioners?.current || 0,
        changeInPercentage:
          summary?.totalPractitioners?.changeInPercentage || 0,
        direction: summary?.totalPractitioners?.direction || Direction.Neutral,
        impact: summary?.totalPractitioners?.impact || Impact.Neutral,
      },
      {
        label: 'Clients',
        value: summary?.totalClients?.current || 0,
        changeInPercentage: summary?.totalClients?.changeInPercentage || 0,
        direction: summary?.totalClients?.direction || Direction.Neutral,
        impact: summary?.totalClients?.impact || Impact.Neutral,
      },
      {
        label: 'Avg. Response Time',
        value: formatDuration(summary?.averageResponseTime?.current || 0),
        rawValue: summary?.averageResponseTime?.current || 0,
        changeInPercentage:
          summary?.averageResponseTime?.changeInPercentage || 0,
        direction: summary?.averageResponseTime?.direction || Direction.Neutral,
        impact: summary?.averageResponseTime?.impact || Impact.Neutral,
      },
    ];
  }, [displayData]);

  const handlePeriodChange = useMemo(
    () => (value: TimeSeriesInterval) => {
      setPeriod(value);
      refetchSummary({
        providerId: authedProviderUser.provider.id,
        input: { interval: value },
      });
    },
    [authedProviderUser.provider.id, refetchSummary],
  );

  return (
    <PageContainer
      containerClassName={classNames(
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
          : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
      )}
    >
      <div className="mb-4 flex flex-row justify-between">
        <div className="w- flex flex-row items-center">
          <div className="font-serif text-subtitle-small text-green-150 md:text-subtitle">
            Insights
          </div>
        </div>
        <div className="flex flex-row items-center gap-x-5">
          <SelectMenu
            label="Period"
            hideLabel={true}
            fieldValue={period}
            onChange={handlePeriodChange}
            fieldOptions={PERIOD_OPTIONS}
            containerClassName="w-40"
          />
        </div>
      </div>

      <hr className="mb-6 border-neutral-75" />

      <section className="flex h-[calc(100vh-232px)] w-full flex-col items-start overflow-y-auto">
        <PerformanceOverview data={performanceData} loading={summaryLoading} />
        <PractitionerList
          practitioners={practitionersData?.practitionerAssignments}
          loading={practitionersLoading}
        />
      </section>
    </PageContainer>
  );
};

export default InsightsPage;
