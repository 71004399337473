import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useEffect } from 'react';

import PageContainer from '../../components/Containers/PageContainer';

import TableContainer from '../../components/TableContainer';
import { useAuth } from '../../../contexts/AuthContext';

import {
  ClientSessionReviewStatus,
  ClientSessionIndividual,
} from '../../../generated/graphql';

import ReviewSessionModal from './components/modals/ReviewSessionModal';
import UpdateSessionModal from './components/modals/UpdateSessionModal';
import { SESSION_STATES } from './utils/constants';

import { useSessionStatus } from './hooks/useSessionStatus';
import { useSessionModal } from './hooks/useSessionModal';
import { SessionModalMode } from './types';
import { useSessionsData } from './hooks/useSessionData';
import { usePageStore } from './hooks/usePageStore';

const ClientSessionsPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showUpgradeBanner } = useAuth();
  const setShowUpgradeBanner = usePageStore(
    (state) => state.setShowUpgradeBanner,
  );
  const setEditHandler = usePageStore((state) => state.setEditHandler);
  const setUpdateHandler = usePageStore((state) => state.setUpdateHandler);
  const setStatus = usePageStore((state) => state.setStatus);

  const { modalType, selectedSession, openModal, closeModal } =
    useSessionModal();

  const currentState = useSessionStatus(pathname);
  const filterStatus = currentState.status;
  const pageTitle = currentState.title;

  const { refetch: refetchSessions, pendingCount } = useSessionsData();

  const handleReview = (session: ClientSessionIndividual) => {
    openModal(session, SessionModalMode.REVIEW);
  };

  const handleEdit = (session: ClientSessionIndividual) => {
    openModal(session, SessionModalMode.UPDATE);
  };

  const handleModalClose = () => {
    closeModal();
  };

  const handleModalSuccess = async () => {
    await refetchSessions();
  };

  useEffect(() => {
    setStatus(filterStatus);
    setShowUpgradeBanner(showUpgradeBanner);

    const editHandler =
      filterStatus === ClientSessionReviewStatus.PendingReview
        ? handleReview
        : handleEdit;

    setEditHandler(editHandler);
    setUpdateHandler(handleEdit);
  }, [filterStatus, showUpgradeBanner]);

  return (
    <PageContainer
      containerClassName={classNames(
        showUpgradeBanner
          ? 'h-[calc(100vh-var(--top-nav-height)-var(--upgrade-banner-height))] overflow-y-hidden'
          : 'h-[calc(100vh-var(--top-nav-height))] overflow-y-hidden',
      )}
    >
      {selectedSession && modalType === SessionModalMode.REVIEW && (
        <ReviewSessionModal
          clientSession={selectedSession}
          onClose={handleModalClose}
          onSuccess={handleModalSuccess}
        />
      )}
      {selectedSession && modalType === SessionModalMode.UPDATE && (
        <UpdateSessionModal
          clientSession={selectedSession}
          onClose={handleModalClose}
          onSuccess={handleModalSuccess}
        />
      )}
      <div className={classNames('h-full opacity-100 transition duration-300')}>
        <div className="mb-4 flex h-[48px] flex-row justify-between">
          <div className="flex flex-col items-start">
            <div className="font-serif text-subtitle text-green-150">
              {pageTitle}
            </div>
            <div className="text-caption text-neutral-125">
              {currentState.description}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-5">
            {/* <Button
              title="Create"
              IconComponent={PlusSmIcon}
              iconPosition="left"
              onClick={() => {
                // TODO: Implement this
                navigate(CLIENT_SESSIONS_NEW_BASE_PATH);
              }}
            /> */}
          </div>
        </div>
        <div className="mb-4 flex h-[56px] w-full flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start">
            {/* Left-side Container */}
          </div>
          <div className="flex flex-row items-center justify-end gap-x-2">
            {Object.values(SESSION_STATES).map((state) => (
              <button
                key={state.path}
                className={classNames(
                  'flex flex-row items-center justify-start rounded-full text-caption text-neutral-125',
                  'px-3 py-1.5',
                  'h-[30px]',
                  pathname.includes(state.path)
                    ? 'bg-neutral-50'
                    : 'hover:bg-neutral-25',
                )}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(state.path);
                }}
              >
                <span>{state.label}</span>
                {state === SESSION_STATES.PENDING && (
                  <div className="ml-2 -mr-3 rounded-full bg-neutral-75 px-2.5 py-1 font-medium">
                    {pendingCount}
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
        <TableContainer>
          <Outlet />
        </TableContainer>
      </div>
    </PageContainer>
  );
};

export default ClientSessionsPage;
