import { useMemo } from 'react';
import {
  createSessionActions,
  createBulkSessionActions,
  type SessionWithActions,
} from '../utils/sessionActions';
import type {
  ClientSession,
  ClientSessionParticipant,
} from '../../../../generated/graphql';

export const useSessionActions = (
  session: ClientSession | ClientSessionParticipant,
): SessionWithActions => {
  return useMemo(() => createSessionActions(session), [session]);
};

export const useBulkSessionActions = (
  sessions: (ClientSession | ClientSessionParticipant)[],
) => {
  return useMemo(() => createBulkSessionActions(sessions), [sessions]);
};
