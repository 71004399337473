import { create } from 'zustand';
import {
  ClientSessionIndividual,
  ClientSessionReviewStatus,
} from '../../../../generated/graphql';

interface PageState {
  status: ClientSessionReviewStatus;
  showUpgradeBanner: boolean;
  tableLoading: boolean;
  onEdit?: (session: ClientSessionIndividual) => void;
  onUpdate?: (session: ClientSessionIndividual) => void;

  setStatus: (status: ClientSessionReviewStatus) => void;
  setShowUpgradeBanner: (show: boolean) => void;
  setTableLoading: (loading: boolean) => void;
  setEditHandler: (handler: (session: ClientSessionIndividual) => void) => void;
  setUpdateHandler: (
    handler: (session: ClientSessionIndividual) => void,
  ) => void;
}

export const usePageStore = create<PageState>((set) => ({
  status: ClientSessionReviewStatus.PendingReview,
  showUpgradeBanner: false,
  tableLoading: false,
  onEdit: undefined,
  onUpdate: undefined,

  setStatus: (status) => set({ status }),
  setShowUpgradeBanner: (show) => set({ showUpgradeBanner: show }),
  setTableLoading: (loading) => set({ tableLoading: loading }),
  setEditHandler: (handler) => set({ onEdit: handler }),
  setUpdateHandler: (handler) => set({ onUpdate: handler }),
}));
