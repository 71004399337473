import { create } from 'zustand';
import { Selectable } from '../components/Table';
import { ClientSessionParticipant } from '../../../../generated/graphql';

interface TableState {
  // Selection state
  selectedSessions: Selectable[];
  toggleSelection: (session: Selectable) => void;
  toggleGroupSelection: (
    groupId: string,
    clients: ClientSessionParticipant[],
  ) => void;
  clearSelection: () => void;

  // Expanded groups state
  expandedGroups: Set<string>;
  toggleGroup: (groupId: string, clients: ClientSessionParticipant[]) => void;

  // Add a method to handle filter changes
  handleFilterChange: () => void;
}

export const useTableStore = create<TableState>((set, get) => ({
  selectedSessions: [],
  toggleSelection: (session) =>
    set((state) => {
      const isSelected = state.selectedSessions.some(
        (s) => s.id === session.id,
      );
      return {
        selectedSessions: isSelected
          ? state.selectedSessions.filter((s) => s.id !== session.id)
          : [...state.selectedSessions, session],
      };
    }),
  toggleGroupSelection: (groupId, clients) =>
    set((state) => {
      const allSelected = clients.every((client) =>
        state.selectedSessions.some((s) => s.id === client.id),
      );

      return {
        selectedSessions: allSelected
          ? state.selectedSessions.filter(
              (session) => !clients.some((client) => client.id === session.id),
            )
          : [...state.selectedSessions, ...clients],
      };
    }),
  clearSelection: () => set({ selectedSessions: [] }),

  expandedGroups: new Set<string>(),
  toggleGroup: (groupId, clients) =>
    set((state) => {
      const newSet = new Set(state.expandedGroups);
      if (newSet.has(groupId)) {
        newSet.delete(groupId);
        // Clear selection for collapsed group
        return {
          expandedGroups: newSet,
          selectedSessions: state.selectedSessions.filter(
            (session) => !clients.some((client) => client.id === session.id),
          ),
        };
      } else {
        newSet.add(groupId);
        return {
          expandedGroups: newSet,
        };
      }
    }),

  handleFilterChange: () =>
    set({
      selectedSessions: [],
      expandedGroups: new Set<string>(),
    }),
}));
