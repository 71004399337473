import { useEffect } from 'react';
import { useSessionsStore } from './useSessionStore';

export const useSessionStatus = (pathname: string) => {
  const { pathStatus, setPathStatus } = useSessionsStore();

  useEffect(() => {
    setPathStatus(pathname);
  }, [pathname, setPathStatus]);

  return pathStatus;
};
