import {
  PencilIcon,
  ChevronRightIcon,
  ClipboardCheckIcon,
  ArchiveIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import Avatar from '../../../../components/Avatar';
import Checkbox from '../../../../components/Checkbox';
import {
  ClientSessionGroup,
  ClientSessionParticipant,
  ClientSessionReviewStatus,
} from '../../../../../generated/graphql';
import { formatTime, getShortDate } from '../../../../lib/time';
import { Selectable } from './index';
import { usePageStore } from '../../hooks/usePageStore';
import { useSessionActions } from '../../hooks/useSessionActions';

interface GroupSessionRowProps {
  group: ClientSessionGroup;
  selectedSessions: Selectable[];
  expandedGroups: Set<string>;
  onToggleGroup: (groupId: string, clients: ClientSessionParticipant[]) => void;
  onToggleSelection: (session: Selectable) => void;
  onToggleGroupSelection: (
    groupId: string,
    clients: ClientSessionParticipant[],
  ) => void;
  onDismiss?: (session: ClientSessionGroup | ClientSessionParticipant) => void;
}

export const GroupSessionRow: React.FC<GroupSessionRowProps> = ({
  group,
  selectedSessions,
  expandedGroups,
  onToggleGroup,
  onToggleSelection,
  onToggleGroupSelection,
  onDismiss,
}) => {
  const status = usePageStore((state) => state.status);
  const isExpanded = expandedGroups.has(group.id);
  const isSelected = (id: string) => selectedSessions.some((s) => s.id === id);
  const areAllParticipantsSelected = group.participants.every((p) =>
    isSelected(p.id),
  );

  const ActionIcon =
    status === ClientSessionReviewStatus.PendingReview
      ? ClipboardCheckIcon
      : PencilIcon;

  const groupActions = useSessionActions(group);
  const participantActions = useSessionActions(group.participants[0]); // Use first participant for action checks

  // TODO: Need to adjust min-w and max-w for each row to match the table
  // header and each row.

  return (
    <div className={classNames('border-l-4 bg-neutral-30')}>
      {/* Group Header */}
      <div
        onClick={() => onToggleGroup(group.id, group.participants)}
        className={classNames(
          'hover:bg-gray-50 grid grid-cols-[auto,1fr,1fr,1fr,1fr,auto] gap-4 whitespace-nowrap py-3 px-4',
          'group max-w-full cursor-pointer whitespace-nowrap text-green-150',
          isSelected(group.id) && 'bg-neutral-25',
          !isSelected(group.id) && 'hover:bg-secondary-20',
          'items-center',
        )}
      >
        <div
          className="-ml-1 flex items-center"
          onClick={(e) => {
            e.stopPropagation();
            onToggleGroupSelection(group.id, group.participants);
          }}
        >
          <Checkbox
            className="z-10"
            id={`checkbox-${group.id}`}
            checked={areAllParticipantsSelected}
          />
        </div>
        <div className="flex items-center">
          {group.participants.map((client) => (
            <Avatar
              key={client.id}
              size="small"
              name={client.name}
              className="mr-3"
            />
          ))}
        </div>
        <div className="flex items-center">
          {group.practitioners.map((practitioner) => (
            <Avatar
              key={practitioner.id}
              size="small"
              name={practitioner.name}
              className="mr-3"
            />
          ))}
        </div>
        <div className="flex items-center text-caption">{group.title}</div>
        <div className="flex flex-col items-start">
          <div className="text-caption font-medium line-clamp-1">
            {getShortDate(group.startTime)}
          </div>
          <div className="text-small-caption text-neutral-125 line-clamp-2">
            {formatTime(group.startTime)} - {formatTime(group.endTime)}
          </div>
        </div>
        <div className="flex w-24 items-center gap-2">
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onToggleGroup(group.id, group.participants);
            }}
            className="rounded-full bg-neutral-50 p-3 transition-transform duration-300 hover:bg-neutral-100/75"
          >
            <ChevronRightIcon
              className={`h-5 w-5 transform transition-transform duration-300 ${
                isExpanded ? 'rotate-90' : 'rotate-0'
              }`}
            />
          </button>
          {groupActions.canDismiss() && (
            <button
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onDismiss?.(group);
              }}
              className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
            >
              <ArchiveIcon className="h-5 w-5 text-neutral-125" />
            </button>
          )}
        </div>
      </div>

      {/* Expanded Group Sessions */}
      <div
        className={`bg-gray-50 overflow-hidden transition-all duration-300 ${
          isExpanded ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        {group.participants.map((participant) => (
          <div
            key={participant.id}
            className={classNames(
              'grid min-w-full grid-cols-[auto,1fr,auto] gap-4 py-2 px-4 text-left text-neutral-150',
              isExpanded && 'border-t border-neutral-75',
            )}
          >
            <div className="flex items-center">
              <Checkbox
                id={`checkbox-${participant.id}`}
                checked={isSelected(participant.id)}
                onChange={() => onToggleSelection(participant)}
              />
            </div>
            <div className="flex items-center">
              <Avatar size="small" name={participant.name} className="mr-3" />
            </div>
            <div className="flex w-24 items-center gap-2">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  // TODO: Edit action
                }}
                className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
              >
                <ActionIcon className="h-5 w-5 text-neutral-125" />
              </button>
              {participantActions.canDismiss() && (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onDismiss?.(participant);
                  }}
                  className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
                >
                  <ArchiveIcon className="h-5 w-5 text-neutral-125" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupSessionRow;
