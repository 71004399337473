import { useEffect, RefObject } from 'react';
import FullCalendar from '@fullcalendar/react';
import { CalendarViewType, ViewMode } from '../utils/enums';
import { Calendar, Resource } from '../components/types';
import { useCalendarViewStore } from '../stores/useCalendarViewStore';

export function useCalendarViewState(
  calendarRef: RefObject<FullCalendar>,
  calendars?: Calendar[],
  resources?: Resource[],
) {
  const {
    viewType,
    viewMode,
    selectedCalendarIds,
    selectedResourceIds,
    dateRange,
    setViewType,
    setViewMode,
    setSelectedCalendarIds,
    setSelectedResourceIds,
    setDateRange,
    initializeSelections,
  } = useCalendarViewStore();

  // Initialize selections when calendars/resources are loaded
  useEffect(() => {
    initializeSelections(calendars, resources);
  }, [calendars, resources, initializeSelections]);

  const handleViewTypeChange = (newViewType: CalendarViewType) => {
    setViewType(newViewType);

    // Update view mode based on view type
    if (newViewType === CalendarViewType.RESOURCES) {
      calendarRef.current?.getApi().changeView(ViewMode.RESOURCE_TIME_GRID_DAY);
      setViewMode(ViewMode.RESOURCE_TIME_GRID_DAY);
    } else {
      calendarRef.current?.getApi().changeView(ViewMode.TIME_GRID_WEEK);
      setViewMode(ViewMode.TIME_GRID_WEEK);
    }

    // Force re-render or events will not display
    calendarRef.current?.render();
  };

  return {
    viewType,
    viewMode,
    selectedCalendarIds,
    selectedResourceIds,
    dateRange,
    handleViewTypeChange,
    setDateRange,
    handleCalendarSelect: setSelectedCalendarIds,
    handleResourceSelect: setSelectedResourceIds,
  };
}
