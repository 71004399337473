import { create } from 'zustand';
import { CalendarViewType, ViewMode } from '../utils/enums';
import { logger } from '../../../../../lib/logger';
import { Calendar, Resource } from '../components/types';
import { persist } from 'zustand/middleware';

interface CalendarViewState {
  viewType: CalendarViewType;
  viewMode: ViewMode;
  selectedCalendarIds: string[];
  selectedResourceIds: string[];
  dateRange: {
    start: string;
    end: string;
  } | null;
  setViewType: (viewType: CalendarViewType) => void;
  setViewMode: (viewMode: ViewMode) => void;
  setSelectedCalendarIds: (ids: string[]) => void;
  setSelectedResourceIds: (ids: string[]) => void;
  setDateRange: (range: { start: Date; end: Date } | null) => void;
  initializeSelections: (
    calendars?: Calendar[],
    resources?: Resource[],
  ) => void;
}

export const useCalendarViewStore = create<CalendarViewState>()(
  persist(
    (set) => ({
      viewType: CalendarViewType.CALENDARS,
      viewMode: ViewMode.TIME_GRID_WEEK,
      selectedCalendarIds: [],
      selectedResourceIds: [],
      dateRange: null,

      setViewType: (type) => set((state) => ({ ...state, viewType: type })),

      setViewMode: (newViewMode) =>
        set((state) => ({ ...state, viewMode: newViewMode })),

      setDateRange: (range) =>
        set((state) => ({
          ...state,
          dateRange: range
            ? {
                start: range.start.toISOString(),
                end: range.end.toISOString(),
              }
            : null,
        })),

      setSelectedCalendarIds: (ids) => {
        logger.debug('📅 Selected Calendars Changed:', { ids });
        set({ selectedCalendarIds: ids });
      },

      setSelectedResourceIds: (ids) => {
        logger.debug('📅 Selected Resources Changed:', { ids });
        set({ selectedResourceIds: ids });
      },

      initializeSelections: (calendars, resources) => {
        set((state) => {
          const updates: Partial<CalendarViewState> = {};

          if (calendars?.length) {
            const selectedIds = state.selectedCalendarIds;
            const validCalendarIds = selectedIds.filter((id) =>
              calendars.some((calendar) => calendar.id === id),
            );

            if (validCalendarIds.length !== selectedIds.length) {
              logger.debug('📅 Filtering out non-existent calendars:', {
                invalidIds: selectedIds.filter(
                  (id) => !validCalendarIds.includes(id),
                ),
              });
              updates.selectedCalendarIds = validCalendarIds;
            }

            if (validCalendarIds.length === 0) {
              const primaryCalendar = calendars.find(
                (calendar) => calendar.isPrimary,
              );
              if (primaryCalendar) {
                logger.debug('📅 Auto-selecting primary calendar:', {
                  calendarId: primaryCalendar.id,
                });
                updates.selectedCalendarIds = [primaryCalendar.id];
              }
            }
          }

          if (resources?.length) {
            const selectedIds = state.selectedResourceIds;
            const validResourceIds = selectedIds.filter((id) =>
              resources.some((resource) => resource.id === id),
            );

            if (validResourceIds.length !== selectedIds.length) {
              logger.debug('📅 Filtering out non-existent resources:', {
                invalidIds: selectedIds.filter(
                  (id) => !validResourceIds.includes(id),
                ),
              });
              updates.selectedResourceIds = validResourceIds;
            }

            if (validResourceIds.length === 0) {
              const firstThreeResources = resources.slice(0, 3);
              if (firstThreeResources.length) {
                logger.debug('📅 Auto-selecting first three resources:', {
                  resourceIds: firstThreeResources.map(
                    (resource) => resource.id,
                  ),
                });
                updates.selectedResourceIds = firstThreeResources.map(
                  (resource) => resource.id,
                );
              }
            }
          }

          return updates;
        });
      },
    }),
    {
      name: 'calendar-view-store',
      partialize: (state) => ({
        viewType: state.viewType,
        viewMode: state.viewMode,
        selectedCalendarIds: state.selectedCalendarIds,
        selectedResourceIds: state.selectedResourceIds,
        dateRange: state.dateRange,
      }),
    },
  ),
);
