import classNames from 'classnames';
import { useAuth } from '../../contexts/AuthContext';
import { withSearchParams } from '../lib/url';

type AvatarSize =
  | 'small'
  | 'medium'
  | 'medium-plus'
  | 'medium-large'
  | 'large'
  | 'xl'
  | 'xxl';

interface AvatarProps {
  imageUrl?: string | null;
  name?: string;
  size?: AvatarSize;
  className?: string;
  textClassName?: string;
  useFullName?: boolean;
  style?: React.CSSProperties;
}

const sizeToPx: Record<AvatarSize, number> = {
  small: 24,
  medium: 32,
  'medium-plus': 40,
  'medium-large': 68,
  large: 90,
  xl: 120,
  xxl: 160,
};

// Fallbacks to the provider user's profile image and name if imageUrl and name are not provided
const Avatar: React.FC<AvatarProps> = ({
  imageUrl,
  name,
  size = 'medium-large',
  useFullName = false,
  className,
  textClassName = 'bg-neutral-110 text-white',
  style,
}) => {
  const { authedProviderUser } = useAuth();

  const diameterPx = sizeToPx[size];

  const profileImageUrl = name
    ? imageUrl
    : authedProviderUser?.profileImageMedia?.url;
  const formattedProfileImageUrl = profileImageUrl
    ? withSearchParams(profileImageUrl, {
        width: diameterPx.toString(),
        crop: '1:1,smart',
      })
    : undefined;

  const username = name ?? authedProviderUser?.name ?? '';

  return (
    <div
      className={classNames(
        'aspect-square shrink-0 grow-0 overflow-hidden rounded-full',
        size === 'small' && 'h-6 w-6 text-[0.8125rem]',
        size === 'medium' && 'h-8 w-8 text-body',
        size === 'medium-plus' && 'h-[2.5rem] w-[2.5rem] text-[1.375rem]',
        size === 'medium-large' && 'h-[3.75rem] w-[3.75rem] text-[2rem]',
        size === 'large' && 'h-[5.625rem] w-[5.625rem] text-[3.125rem]',
        size === 'xl' && 'h-[7.5rem] w-[7.5rem] text-[4rem]',
        size === 'xxl' && 'h-[10rem] w-[10rem] text-[5rem]',
        className,
      )}
      style={style}
      title={name}
    >
      {!useFullName && formattedProfileImageUrl ? (
        <img
          src={formattedProfileImageUrl}
          className={classNames('h-full w-full object-cover object-center')}
          height={diameterPx}
          width={diameterPx}
          alt="Avatar"
        />
      ) : (
        <div
          className={classNames(
            'flex h-full w-full items-center justify-center font-medium',
            textClassName,
          )}
        >
          {!useFullName
            ? username.charAt(0).toUpperCase()
            : username.toUpperCase()}
        </div>
      )}
    </div>
  );
};

export default Avatar;
