import { create } from 'zustand';
import {
  ClientSessionParticipant,
  ClientSessionReviewStatus,
} from '../../../../generated/graphql';
import { Selectable } from '../components/Table';
import { SESSION_STATES } from '../utils/constants';
import { useTableStore } from './useTableStore';

interface SessionsState {
  currentStatus: ClientSessionReviewStatus;
  selectedSessions: Selectable[];
  expandedGroups: Set<string>;
  currentPage: number;
  pathStatus: typeof SESSION_STATES[keyof typeof SESSION_STATES];

  // Actions
  setCurrentStatus: (status: ClientSessionReviewStatus) => void;
  setSelectedSessions: (sessions: Selectable[]) => void;
  clearSelection: () => void;
  toggleGroup: (groupId: string, clients: ClientSessionParticipant[]) => void;
  setNextPage: () => void;
  setPreviousPage: () => void;
  setPathStatus: (pathname: string) => void;
}

export const useSessionsStore = create<SessionsState>((set, get) => ({
  // Initial state
  currentStatus: ClientSessionReviewStatus.PendingReview,
  selectedSessions: [],
  expandedGroups: new Set<string>(),
  pathStatus: SESSION_STATES.PENDING,
  currentPage: 1,

  // Actions
  setCurrentStatus: (status) =>
    set((state) => {
      if (state.currentStatus === status) {
        return { currentStatus: status };
      }

      // Clear table selections when status changes
      useTableStore.getState().handleFilterChange();

      return {
        currentStatus: status,
        currentPage: 1,
        selectedSessions: [],
        expandedGroups: new Set(),
      };
    }),

  setSelectedSessions: (sessions) => set({ selectedSessions: sessions }),

  clearSelection: () => set({ selectedSessions: [] }),

  toggleGroup: (groupId, clients) =>
    set((state) => ({
      expandedGroups: state.expandedGroups.has(groupId)
        ? new Set(
            [...Array.from(state.expandedGroups)].filter(
              (id) => id !== groupId,
            ),
          )
        : new Set([...Array.from(state.expandedGroups), groupId]),
    })),

  setNextPage: () => set((state) => ({ currentPage: state.currentPage + 1 })),

  setPreviousPage: () =>
    set((state) => ({ currentPage: Math.max(state.currentPage - 1, 1) })),

  setPathStatus: (pathname) =>
    set(() => {
      const newPathStatus =
        Object.values(SESSION_STATES).find((state) =>
          pathname.includes(state.path),
        ) || SESSION_STATES.PENDING;

      const statusMap = {
        [SESSION_STATES.PENDING.path]: ClientSessionReviewStatus.PendingReview,
        [SESSION_STATES.CONFIRMED.path]: ClientSessionReviewStatus.Confirmed,
        [SESSION_STATES.DISMISSED.path]: ClientSessionReviewStatus.Dismissed,
      };

      const newCurrentStatus = statusMap[newPathStatus.path];

      // Clear table selections when path changes
      useTableStore.getState().handleFilterChange();

      return {
        pathStatus: newPathStatus,
        currentStatus: newCurrentStatus,
        currentPage: 1,
      };
    }),
}));
