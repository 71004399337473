import { FC } from 'react';
import Modal from '../../../../components/Modal';
import ClientSessionForm, {
  ClientSessionFormData,
} from '../forms/ClientSessionForm';
import {
  useUpdateClientSessionFormMutation,
  useFindClientSessionFormByTypeQuery,
  ClientSessionFormType,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { logger } from '../../../../../lib/logger';
import { SessionModalProps } from '../../types';

const UpdateSessionModal: FC<SessionModalProps> = ({
  clientSession,
  onClose,
  onSuccess,
}) => {
  const [submitForm, { loading: isSubmitting }] =
    useUpdateClientSessionFormMutation();
  const { data: formData, loading: isLoading } =
    useFindClientSessionFormByTypeQuery({
      variables: {
        clientSessionId: clientSession.id,
        formType: ClientSessionFormType.Sb303V1,
      },
    });

  const handleSubmit = async (data: ClientSessionFormData) => {
    try {
      const response = await submitForm({
        variables: {
          input: {
            formId: formData?.findClientSessionFormByType.id,
            data: data,
          },
        },
      });

      if (response.data?.updateClientSessionForm.success) {
        customToast.success('Session updated successfully');
        onSuccess();
        onClose();
      }

      return {
        success: response.data?.updateClientSessionForm.success ?? false,
        errors: response.data?.updateClientSessionForm.errors,
      };
    } catch (error) {
      logger.error(error);
      return {
        success: false,
        errors: [{ field: 'root', message: 'Failed to update session' }],
      };
    }
  };

  if (isLoading) {
    return null; // Or show loading state
  }

  return (
    <Modal
      name="UpdateSession"
      isOpen={true}
      setClosed={onClose}
      width="medium"
    >
      <ClientSessionForm
        initialData={formData?.findClientSessionFormByType.data}
        onSubmit={handleSubmit}
        onCancel={onClose}
        isSubmitting={isSubmitting}
        mode="update"
      />
    </Modal>
  );
};

export default UpdateSessionModal;
