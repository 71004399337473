import { useCallback, useState } from 'react';
import { ClientSessionIndividual } from '../../../../generated/graphql';
import { SessionModalMode, SessionModalType } from '../types';

export const useSessionModal = () => {
  const [modalType, setModalType] = useState<SessionModalType>(null);
  const [selectedSession, setSelectedSession] =
    useState<ClientSessionIndividual | null>(null);

  const handleModalOpen = useCallback(
    (session: ClientSessionIndividual, type: SessionModalMode) => {
      setSelectedSession(session);
      setModalType(type);
    },
    [],
  );

  const handleModalClose = useCallback(() => {
    setSelectedSession(null);
    setModalType(null);
  }, []);

  return {
    modalType,
    selectedSession,
    openModal: handleModalOpen,
    closeModal: handleModalClose,
  };
};
