import {
  PencilIcon,
  ClipboardCheckIcon,
  ArchiveIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import Avatar from '../../../../components/Avatar';
import Checkbox from '../../../../components/Checkbox';
import {
  ClientSessionIndividual,
  ClientSessionReviewStatus,
} from '../../../../../generated/graphql';
import { formatTime, getShortDate } from '../../../../lib/time';
import { Selectable } from './index';
import { useSessionActions } from '../../hooks/useSessionActions';
import { usePageStore } from '../../hooks/usePageStore';

interface IndividualSessionRowProps {
  session: ClientSessionIndividual;
  selectedSessions: Selectable[];
  onToggleSelection: (session: Selectable) => void;
  onEdit?: (session: ClientSessionIndividual) => void;
  onDismiss?: (session: ClientSessionIndividual) => void;
}

export const IndividualSessionRow: React.FC<IndividualSessionRowProps> = ({
  session,
  selectedSessions,
  onToggleSelection,
  onEdit,
  onDismiss,
}) => {
  const isSelected = selectedSessions.some((s) => s.id === session.id);
  const sessionActions = useSessionActions(session);
  const { status, onEdit: contextOnEdit } = usePageStore();

  const ActionIcon =
    status === ClientSessionReviewStatus.PendingReview
      ? ClipboardCheckIcon
      : PencilIcon;

  return (
    <div
      key={session.id}
      className={classNames(
        'hover:bg-gray-50 grid grid-cols-[auto,1fr,1fr,1fr,1fr,auto] gap-4 whitespace-nowrap py-3 px-4',
        'group max-w-full cursor-pointer whitespace-nowrap text-green-150',
        isSelected && 'bg-neutral-25',
        !isSelected && 'hover:bg-secondary-20',
        'items-center',
      )}
    >
      <div className="flex items-center">
        <Checkbox
          id={`checkbox-${session.id}`}
          checked={isSelected}
          onChange={() => onToggleSelection(session)}
        />
      </div>
      <div className="flex min-w-[150px] items-center">
        <Avatar
          size="medium"
          name={session.participant.name}
          className="mr-3"
        />
        <span className="overflow-hidden truncate font-serif text-extra-small text-green-150">
          {session.participant.name}
        </span>
      </div>
      <div className="flex min-w-[100px] max-w-[220px] items-center overflow-hidden">
        {session.practitioners.map((practitioner, index) => (
          <Avatar
            key={practitioner.id}
            size="medium"
            name={practitioner.name}
            className={classNames(
              'hover:z-10 hover:border-2 hover:border-white',
              index !== 0 && '-ml-3',
            )}
          />
        ))}
      </div>
      <div className="flex min-w-[110px] items-center overflow-hidden truncate text-caption">
        {session.title}
      </div>
      <div className="flex min-w-[110px] flex-col items-start">
        <div className="text-caption font-medium line-clamp-1">
          {getShortDate(session.startTime)}
        </div>
        <div className="text-small-caption text-neutral-125 line-clamp-2">
          {formatTime(session.startTime)} - {formatTime(session.endTime)}
        </div>
      </div>
      <div className="flex w-24 items-center gap-2">
        {sessionActions.canEdit() && (
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              contextOnEdit?.(session);
            }}
            className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
          >
            <ActionIcon className="h-5 w-5 text-neutral-125" />
          </button>
        )}
        {sessionActions.canDismiss() && (
          <button
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onDismiss?.(session);
            }}
            className="rounded-full bg-neutral-50 p-3 hover:bg-neutral-100/75"
          >
            <ArchiveIcon className="h-5 w-5 text-neutral-125" />
          </button>
        )}
      </div>
    </div>
  );
};

export default IndividualSessionRow;
