import { XIcon } from '@heroicons/react/outline';
import { ArchiveIcon } from '@heroicons/react/outline';
import Button from '../../../../components/Button';
// import LinkOutlineIcon from '../../../../svgs/LinkOutlineIcon';
// import LinkSlashOutlineIcon from '../../../../svgs/LinkSlashOutlineIcon';
import {
  ClientSession,
  ClientSessionGroup,
  ClientSessionIndividual,
} from '../../../../../generated/graphql';
import { Selectable } from './index';
import { useBulkSessionActions } from '../../hooks/useSessionActions';
import { useSessionsData } from '../../hooks/useSessionData';

interface SessionActionsProps {
  selectedSessions: Selectable[];
  clearSelection: () => void;
  sessions: ClientSession[];
  onDismiss?: (sessions: Selectable[]) => void;
}

const SessionActions: React.FC<SessionActionsProps> = ({
  selectedSessions,
  clearSelection,
  sessions,
  onDismiss,
}) => {
  const { refetch: refetchSessions } = useSessionsData();
  const bulkActions = useBulkSessionActions(selectedSessions);

  const isIndividualSession = (
    item: Selectable,
  ): item is ClientSessionIndividual => {
    return item.__typename === 'ClientSessionIndividual';
  };

  const isGroupSession = (item: Selectable): item is ClientSessionGroup => {
    return item.__typename === 'ClientSessionGroup';
  };

  const canGroup =
    selectedSessions.length > 1 &&
    selectedSessions.every((item) => isIndividualSession(item));
  const canUngroup = sessions.some((item) => isGroupSession(item));

  const handleAction = async (action: 'dismiss' | 'group' | 'ungroup') => {
    try {
      switch (action) {
        case 'dismiss':
          onDismiss?.(selectedSessions);
          break;
        case 'group':
          if (canGroup) {
            // TODO: Implement group action
            await refetchSessions();
            clearSelection();
          }
          break;
        case 'ungroup':
          if (canUngroup) {
            // TODO: Implement ungroup action
            await refetchSessions();
            clearSelection();
          }
          break;
      }

      // TODO: Move these post-actions to the hooks like useGroupSessions and useUngroupSessions
      // await refetchSessions();
      // clearSelection();
    } catch (error) {
      console.error(`Error during ${action} action:`, error);
    }
  };

  return (
    <div
      className={`${
        selectedSessions.length > 0
          ? 'max-h-20 translate-y-0 opacity-100'
          : 'max-h-0 -translate-y-2 opacity-0'
      } overflow-hidden transition-all duration-200 ease-in-out`}
    >
      <div className="bg-gray-100 flex items-center gap-4 rounded-t-lg p-4">
        <span className="text-sm text-gray-600">
          {selectedSessions.length} selected
        </span>
        <Button
          title="Clear"
          size="extra-small"
          theme="secondary"
          IconComponent={XIcon}
          iconPosition="right"
          disabled={false}
          onClick={clearSelection}
        />

        <div className="flex-1" />

        {/* TODO: Implement group and ungroup actions */}
        {/* <Button
          title="Group"
          size="small"
          theme="primary"
          IconComponent={LinkOutlineIcon}
          iconPosition="left"
          disabled={!canGroup}
          onClick={() => handleAction('group')}
        />

        <Button
          title="Ungroup"
          size="small"
          theme="destructive"
          IconComponent={LinkSlashOutlineIcon}
          iconPosition="left"
          disabled={!canUngroup}
          onClick={() => handleAction('ungroup')}
        /> */}

        {bulkActions.canDismissAll() && (
          <Button
            title="Dismiss"
            size="small"
            theme="secondary"
            IconComponent={ArchiveIcon}
            iconPosition="left"
            onClick={() => {
              handleAction('dismiss');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SessionActions;
