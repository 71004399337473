import { ClientSessionIndividual } from '../../../generated/graphql';

export interface SessionModalProps {
  clientSession: ClientSessionIndividual;
  onClose: () => void;
  onSuccess: () => void;
}

export enum SessionModalMode {
  REVIEW = 'review',
  UPDATE = 'update',
}

export type SessionModalType = SessionModalMode | null;
