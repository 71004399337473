import PractitionerCard from './PractitionerCard';

const PractitionerList = ({ practitioners = [], loading }) => {
  if (loading) return null;

  return (
    <>
      {practitioners.map((practitionerData, index) => (
        <PractitionerCard
          key={index}
          delay={index * 0.2}
          practitioner={practitionerData.practitioner}
          clients={practitionerData.assignedClients}
        />
      ))}
    </>
  );
};

export default PractitionerList;
