import type {
  ClientSession,
  ClientSessionParticipant,
} from '../../../../generated/graphql';

import { ClientSessionReviewStatus } from '../../../../generated/graphql';

export type SessionWithActions = {
  canEdit: () => boolean;
  canDismiss: () => boolean;
  // Add other actions as needed
};

// Pure utility functions
export const createSessionActions = (
  session: ClientSession | ClientSessionParticipant,
): SessionWithActions => ({
  canEdit: () => {
    if (session.__typename === 'ClientSessionIndividual') {
      return session.reviewStatus !== ClientSessionReviewStatus.Dismissed;
    }
    if (session.__typename === 'ClientSessionParticipant') {
      return false; // TODO: Participants edit?
    }
    return false;
  },
  canDismiss: () => {
    // Allow dismiss for individual sessions and groups
    // Only allow for PendingReview or Confirmed status
    if (session.__typename === 'ClientSessionIndividual') {
      return [
        ClientSessionReviewStatus.PendingReview,
        ClientSessionReviewStatus.Confirmed,
      ].includes(session.reviewStatus);
    }
    if (session.__typename === 'ClientSessionGroup') {
      return [
        ClientSessionReviewStatus.PendingReview,
        ClientSessionReviewStatus.Confirmed,
      ].includes(session.reviewStatus);
    }
    return false;
  },
});

export const createBulkSessionActions = (
  sessions: (ClientSession | ClientSessionParticipant)[],
) => ({
  canDismissAll: () =>
    sessions.every((session) => createSessionActions(session).canDismiss()),
  canEditAll: () =>
    sessions.every((session) => createSessionActions(session).canEdit()),
});
