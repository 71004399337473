import classNames from 'classnames';
import Avatar from '../../components/Avatar';
import Tooltip from '../../components/Tooltip';
import { pluralize } from '../../lib/copy';
import {
  AssignedClient,
  ProgramTag,
  TimeSeriesInterval,
  useClientsPerformanceSummaryQuery,
} from '../../../generated/graphql';
import { useMemo } from 'react';
import { formatDuration } from '../../lib/time';
import { PROGRAM_TAG_COLOR_TO_TAILWIND_HOVER_COLOR_MAP } from '../../lib/colors';
import Button from '../../components/Button';
import Tag from '../../svgs/Tag';
import { PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP } from '../../lib/colors';
import { useNavigate } from 'react-router-dom';

interface ClientTableProps {
  clients: AssignedClient[];
  practitionerId: string;
}

const LoadingCell = () => (
  <span className="inline-block animate-pulse rounded px-4">-</span>
);

const EmptyCell = () => <span className="text-neutral-100">-</span>;

const formatDurationCell = (
  value: number | null | undefined,
  loading: boolean,
) => {
  if (loading) return <LoadingCell />;
  if (value == null) return <EmptyCell />;
  return formatDuration(value);
};

const formatDateCell = (value: number | null | undefined, loading: boolean) => {
  if (loading) return <LoadingCell />;
  if (value == null) return <EmptyCell />;
  return new Date(value).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

const formatMetricCell = (
  value: number | null | undefined,
  loading: boolean,
) => {
  if (loading) return <LoadingCell />;
  if (value == null || value === 0) return <EmptyCell />;
  return value;
};

const ClientTable = ({ clients, practitionerId }: ClientTableProps) => {
  const navigate = useNavigate();
  const navigateToClientProfile = (programId: string) => {
    navigate(`/clients/${programId}`);
  };

  const { data, loading } = useClientsPerformanceSummaryQuery({
    variables: {
      input: {
        practitionerId,
        clientIds: clients.map((client) => client.id),
        interval: TimeSeriesInterval.Week,
        dateRange: {
          startDate: '2024-01-01',
          endDate: '2025-01-30',
        },
      },
    },
    skip: clients.length === 0,
  });

  const clientPerformanceMap = useMemo(() => {
    if (!data?.clientsPerformanceSummary?.clients) return {};
    return data.clientsPerformanceSummary.clients.reduce(
      (acc, client) => ({
        ...acc,
        [client.id]: client,
      }),
      {},
    );
  }, [data]);

  const totals = data?.clientsPerformanceSummary?.totals;

  return (
    <table className="w-full">
      <thead>
        <tr
          className={classNames(
            'border-b border-neutral-75',
            'text-left text-small-caption font-medium text-neutral-150',
          )}
        >
          <th className="min-w-[300px] pl-4">Client</th>
          <th className="min-w-[80px] pl-0">Alerts</th>
          <th className="min-w-[80px] pl-0">Sessions</th>
          <th className="min-w-[100px] pl-0">
            <Tooltip
              content={
                <>
                  <div className="mb-1 font-medium">Average Response Time</div>
                  <div className="text-xs font-normal">
                    The average time it takes for a practitioner to respond to a
                    client's chat message.
                  </div>
                </>
              }
              placement="bottom"
              showArrow
            >
              <span className="underline decoration-dotted underline-offset-4">
                ART
              </span>
            </Tooltip>
          </th>
          <th className="min-w-[120px] pl-0">Added</th>
          <th className="w-[320px] pl-0">Tags</th>
          <th className="w-[3.75rem]">{/* Overflow menu can go here */}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-neutral-30 font-medium text-neutral-125">
          <td className="pl-4 font-sans text-caption">
            {pluralize(clients.length, 'active client', 'active clients')}
          </td>
          <td className="pl-0">
            {formatMetricCell(totals?.totalAlerts, loading)}
          </td>
          <td className="pl-0">
            {formatMetricCell(totals?.totalSessions, loading)}
          </td>
          <td className="pl-0">
            {formatDurationCell(totals?.averageResponseTime, loading)}
          </td>
          <td colSpan={3} className="w-[3.75rem]" />
        </tr>
        {clients.map((client, index) => {
          const performanceData = clientPerformanceMap[client.id];
          return (
            <tr
              key={index}
              className="whitespace-nowrap font-sans text-caption text-neutral-110"
            >
              <td className="w-1/6 py-4 pl-4">
                <div
                  className="flex cursor-pointer flex-row items-center gap-x-4"
                  onClick={() =>
                    navigateToClientProfile(client.latestProgramId)
                  }
                >
                  <Avatar
                    size="medium-plus"
                    name={client.name}
                    imageUrl={client.profileImageUrl}
                  />
                  <span
                    className="w-[220px] overflow-hidden text-ellipsis font-serif text-extra-small text-green-150"
                    title={client.name}
                  >
                    {client.name}
                  </span>
                </div>
              </td>
              <td className="pl-0">
                {formatMetricCell(
                  performanceData?.metrics.totalAlerts,
                  loading,
                )}
              </td>
              <td className="pl-0">
                {formatMetricCell(
                  performanceData?.metrics.totalSessions,
                  loading,
                )}
              </td>
              <td className="pl-0">
                {formatDurationCell(
                  performanceData?.metrics.averageResponseTime,
                  loading,
                )}
              </td>
              <td className="pl-0">
                {formatDateCell(performanceData?.startedAt, loading)}
              </td>
              <td className="pl-0">
                {!loading && performanceData ? (
                  <div className="flex flex-row flex-wrap gap-1">
                    {performanceData.tags.map((tag: ProgramTag) => (
                      <Button
                        key={tag.id}
                        size="smaller"
                        theme="secondary"
                        title={tag.name}
                        IconComponent={Tag}
                        iconPosition="left"
                        className={classNames(
                          PROGRAM_TAG_COLOR_TO_TAILWIND_BG_COLOR_MAP[tag.color],
                          PROGRAM_TAG_COLOR_TO_TAILWIND_HOVER_COLOR_MAP[
                            tag.color
                          ],
                        )}
                      />
                    ))}
                  </div>
                ) : (
                  '-'
                )}
              </td>
              <td className="w-[3.75rem]" />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ClientTable;
