import React from 'react';
import classNames from 'classnames';

const Pagination: React.FC<{
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  totalCount: number;
  currentCount: number;
  onPreviousPage: () => void;
  onNextPage: () => void;
}> = ({
  hasNextPage,
  hasPreviousPage,
  totalCount,
  currentCount,
  onPreviousPage,
  onNextPage,
}) => {
  return (
    <div className="flex items-center justify-center space-x-4 py-4">
      <button
        onClick={onPreviousPage}
        disabled={!hasPreviousPage}
        className={classNames(
          'rounded-md px-4 py-2 text-small-caption',
          'border border-neutral-75 bg-white text-green-150',
          'hover:bg-neutral-25 focus:outline-none focus:ring-2 focus:ring-secondary-50',
          'disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        Previous
      </button>

      <span className="text-caption text-neutral-125">
        Showing {currentCount} of {totalCount} sessions
      </span>

      <button
        onClick={onNextPage}
        disabled={!hasNextPage}
        className={classNames(
          'rounded-md px-4 py-2 text-small-caption',
          'border border-neutral-75 bg-white text-green-150',
          'hover:bg-neutral-25 focus:outline-none focus:ring-2 focus:ring-secondary-50',
          'disabled:cursor-not-allowed disabled:opacity-50',
        )}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
