import { useEffect } from 'react';
import {
  useGetClientSessionsLazyQuery,
  useGetClientSessionsCountQuery,
  ClientSessionReviewStatus,
} from '../../../../generated/graphql';
import { useSessionsStore } from './useSessionStore';
import { PAGE_SIZE } from '../utils/constants';

export const useSessionsData = () => {
  const { currentStatus, currentPage } = useSessionsStore();

  const [getClientSessions, { data, loading, refetch }] =
    useGetClientSessionsLazyQuery();

  const { data: countData, refetch: refetchCount } =
    useGetClientSessionsCountQuery({
      variables: {
        input: { reviewStatus: ClientSessionReviewStatus.PendingReview },
      },
    });

  const fetchSessions = () => {
    getClientSessions({
      variables: {
        input: {
          filter: { reviewStatus: currentStatus },
          page: currentPage,
          pageSize: PAGE_SIZE,
        },
      },
    });
  };

  useEffect(() => {
    fetchSessions();
  }, [currentPage, currentStatus]);

  const refetchAll = async () => {
    await Promise.all([refetch(), refetchCount()]);
  };

  return {
    sessions: data?.clientSessions.edges ?? [],
    pageInfo: data?.clientSessions.pageInfo,
    totalCount: data?.clientSessions.totalCount ?? 0,
    pendingCount: countData?.clientSessionsCount ?? 0,
    loading,
    refetch: refetchAll,
    fetchSessions,
  };
};
